<template>
  <div>
    <app-messages></app-messages>
    <!-- options -->
    <div class="my-2">
      <!-- select button -->
      <b-button v-if="selected.length > 0 && (selection === 'single' || selection === 'range')" @click="select" variant="dark">
        <b-icon-check-circle></b-icon-check-circle>
        {{ $t('FILE_CONFIRM_SELECTION_LABEL') }}
      </b-button>
      <!-- select button -->

      <!-- download all button -->
      <b-button v-if="selected.length > 1" @click="downloadSelected" variant="dark">
        <b-icon-file-zip></b-icon-file-zip>
        {{ $t('FILE_DOWNLOAD_ALL_LABEL') }}
      </b-button>
      <!-- download all button -->

      <!-- delete all button -->
      <b-button v-if="selected.length > 1" class="ml-1" @click="removeAll" variant="dark">
        <b-icon-x></b-icon-x>
        {{ $t('FILE_DELETE_ALL_LABEL') }}
      </b-button>
      <!-- delete all button -->

      <!-- upload button -->
      <b-button class="ml-1" @click="triggerUpload" variant="dark">
        <b-icon-upload></b-icon-upload>
        {{ $t('FILE_UPLOAD_LABEL') }}
      </b-button>
      <!-- upload button -->

      <!-- Excel export -->
      <download-excel class="ml-1 btn btn-dark"
                      :data="files"
                      :fields="excelFields"
                      :worksheet="labels.users"
                      :name="labels.excel">
        <b-icon-file-earmark-excel class="mr-1"></b-icon-file-earmark-excel>
        {{ $t('FILE_LIST_TO_EXCEL_LABEL') }}
      </download-excel>
      <!-- Excel export -->

      <!-- refresh button -->
      <b-button class="ml-1" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') }}</span>
      </b-button>
      <!-- refresh button -->
    </div>
    <!-- options -->

    <b-table
        v-if="id"
        @row-selected="selectRow"
        v-model="files"
        hover selectable
        :select-mode="selection"
        :fields="fields"
        :items="getFiles"
        :busy.sync="loadingFiles"
        id="files-table"
        ref="filesTable">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('FILES')}) }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>


      <!-- fileName -->
      <template #head(fileName)="">
        <span>{{ labels.fileName }}</span>
      </template>

      <template #cell(fileName)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.fileName }}</span>
        </div>
      </template>
      <!-- fileName -->

      <!-- fileType -->
      <template #head(fileType)="">
        <span>{{ labels.fileType }}</span>
      </template>

      <template #cell(fileType)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.fileType }}</span>
        </div>
      </template>
      <!-- fileType -->

      <!-- size -->
      <template #head(size)="">
        <span>{{ labels.size }}</span>
      </template>

      <template #cell(size)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.size | mb }}</span>
        </div>
      </template>
      <!-- size -->

      <template #head(options)>
        <span>{{ labels.options }}</span>
      </template>
      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item @click="download(data.item.fileName)">
              {{ $t('FILE_DOWNLOAD_LABEL') }}
            </b-dropdown-item>
            <b-dropdown-item @click="remove(data.item.fileName)">
              {{ $t('FILE_DELETE_LABEL') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    {{ selected.length > 0 ? $t('FILE_SELECTED_LABEL', {count: selected.length}) : '' }}
    <input @change="upload" :value="file" type="file" :id="name" :ref="name" class="d-none">
  </div>
</template>

<script>
import Files from "@/files/index";
import Constants from "@/constants";
import {SendsMessages} from "@/mixins";
import Numeral from "numeral";
import AppMessages from "@/layout/AppMessages";

export default {
  name: "FilesResume",
  components: {AppMessages},
  mixins: [SendsMessages],
  props: ['id', 'selection', 'name', 'label', 'validExtensions', 'maxMB', 'description'],
  filters: {
    mb(val) {
      return `${Numeral(val / (1024 * 1024)).format("0.00")} MB`;
    }
  },
  mounted() {
    this.tableRef.$on('row-selected', (data) => {
      this.selectedCount = data.length;
      this.allSelected = this.files.length === data.length;
    });
  },
  methods: {
    async getFiles() {
      try {
        this.loadingFiles = true;
        const resp = await Files.getFiles(this.id);
        return resp.data;
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('ERROR_LOADING_FILES', {}, e);
      } finally {
        this.loadingFiles = false;
      }
    },
    refresh() {
      this.$refs.filesTable.refresh();
    },
    select() {
      this.$emit('change', this.selected);
    },
    selectRow(selected) {
      this.selected = selected.map(x => x.fileName);
    },
    setSelectedRow(fileName) {
      const idx = this.files.map(x => x.fileName).indexOf(fileName);
      if(idx !== -1) {
        this.$refs.filesTable.selectRow(idx);
      }
    },
    downloadSelected() {
      try {
        this.loadingFiles = true;
        Files.downloadAll(this.id, this.selected);
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('ERROR_DOWNLOADING_FILES', {}, e);
      } finally {
        this.loadingFiles = false;
      }
    },
    removeAll() {
      try {
        this.loadingFiles = true;
        if (confirm(this.$t('DELETE_ALL_FILES_MESSAGE', {qty: this.selected.length}))) {
          console.log('foo')
        }
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('ERROR_DELETING_FILE', {name}, e);
      } finally {
        this.loadingFiles = false;
      }
    },
    triggerUpload() {
      this.$refs[this.name].click();
    },
    async upload() {
      try {
        this.loadingFiles = true;
        const file = this.$refs[this.name].files[0];
        if (this.maxMB) {
          const sizeMB = file.size / 1024000;
          if (this.maxMB < sizeMB) {
            this.sendError('FILE_MAX_SIZE_ERROR', {name: this.name, size: sizeMB, max: this.maxMB});
            return;
          }
        }
        if (this.validExtensions) {
          const split = file.name.split('.');
          const ext = split[split.length - 1];
          if (this.validExtensions.indexOf(ext) === -1) {
            this.sendError('INVALID_FILE_EXTENSION', {name: this.name, ext, valid: this.validExtensions});
            return;
          }
        }
        const resp = await Files.upload(this.id, this.name, file);
        const uploaded = resp.data;
        this.sendMessage('FILE_UPLOADED_SUCCESS', {file: uploaded.fileName});
        this.files.push(uploaded);
        console.log(uploaded);
        console.log(this.files);
        this.refresh();
        this.setSelectedRow(uploaded.fileName);
      } catch (e) {
        this.sendError('FILE_UPLOAD_ERROR', {}, e);
      } finally {
        this.loadingFiles = false;
      }
    },
    async remove(name) {
      try {
        if (confirm(this.$t('DELETE_FILE_MESSAGE', {name}))) {
          this.loadingFiles = true;
          await Files.deleteFile(this.id, name);
          this.removeFileUI(name);
          this.sendMessage('FILE_DELETED_MESSAGE', {name});
        }
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('ERROR_DELETING_FILE', {name}, e);
      } finally {
        this.loadingFiles = false;
      }
    },
    removeFileUI(name) {
      const idx = this.files.map(x => x.fileName).indexOf(name);
      if (idx !== -1) {
        this.files.splice(idx, 1);
      }
    },
    async download(name) {
      try {
        await Files.getFile(this.id, name);
      } catch (e) {
        if (Constants.DEBUG) {
          console.error(e);
        }
        this.sendError('ERROR_DOWNLOADING_FILE', {}, e);
      }
    },
    handleSelection(selected) {
      this.$emit('change', selected);
    },
    isChecked: function (data) {
      const href = data.item.id;
      const idx = this.originals.indexOf(href);
      if (idx !== -1) {
        this.originals.splice(idx, 1);
        this.selectedCount++;
        data.selectRow();
      }
      return data.rowSelected;
    },
    toggleRow(data) {
      data.rowSelected ? data.unselectRow(data.index) : data.selectRow(data.index);
    },
    selectAll() {
      this.allSelected ? this.tableRef.clearSelected() : this.tableRef.selectAllRows();
    },
  },

  data() {
    return {
      files: [],
      file: undefined,
      selected: [],
      originals: [],
      loadingFiles: false,
      allSelected: false,
      labels: {
        fileName: this.$t('FILE_NAME_LABEL'),
        fileType: this.$t('FILE_TYPE_LABEL'),
        size: this.$t('FILE_SIZE_LABEL'),
        options: this.$t('LIST_OPTIONS_LABEL'),
      },
    }
  },
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'fileName', sortable: true, tdClass: 'customTd'},
        {key: 'fileType', sortable: true, tdClass: 'customTd'},
        {key: 'size', sortable: true, tdClass: 'customTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    excelFields() {
      const fields = {};

      fields[this.labels.fileName] = 'fileName';
      fields[this.labels.fileType] = 'fileType';
      fields[this.labels.size] = 'size';

      return fields;
    },
    tableRef() {
      return this.$refs.filesTable;
    },
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}
.nameTd {
  width: 75% !important;
}
</style>
